import CopyIcon from './CopyIcon'

type TClipboardProps = {
  // value: string | undefined
  description?: string
  children?: any
}

export default function Copyable(props: TClipboardProps) {
  const { children } = props

  function copy() {
    // if (!value) return
    if (navigator) navigator.clipboard.writeText(children)
  }

  return (
    <>
      <button className="pr-1" onClick={() => copy()}>
        <CopyIcon height="14" fill="#aaa" />
      </button>
      <span className="copyableValue">{children}</span>
    </>
  )
}
