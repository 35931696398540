function CircleIconPaths(props: any) {
  return (
    <g {...props}>
      <circle cx="10" cy="10" r="10" />
    </g>
  )
}

export default function CircleIcon(props: any) {
  return (
    <svg
      aria-hidden="true"
      width="12"
      height="12"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <CircleIconPaths />
    </svg>
  )
}
