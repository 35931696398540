import CircleIcon from './CircleIcon'

interface IndicatorProps {
  color: string
  text: string
}
export function Indicator(props: IndicatorProps) {
  const { color, text } = props
  return (
    <div className="inline-block ring-1 ring-white/30 rounded-md bg-bullfinch-800 pt-1 pb-1 pr-2 pl-2">
      <div className="flex flex-row items-center">
        <span className="text-xs uppercase text-white pr-2 font-bold">
          {text}
        </span>
        <CircleIcon fill={color} />
      </div>
    </div>
  )
}
