import { Devices } from '../../models'
import Copyable from '../../components/Copyable'

export const DevicePropertyTable = ({ device }: { device: Devices }) => {
  return (
    <div className="text-xs text-puffin-700 font-mono">
      <table className="border-collapse bg-white/30 w-full table-fixed break-words">
        <thead>
          <tr className="border border-puffin-700/20 bg-stone-200">
            <th className="p-1 pr-3 text-right w-32">Property</th>
            <th className="p-1 pr-3 text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          <PropertyRow property="Model #" value={device.modelName} />
          <PropertyRow property="Serial #" value={device.id} />
          <PropertyRow property="IMEI #" value="863692039500020" />
          <PropertyRow property="Mfg Date" value="2022-12-05" />
          <PropertyRow
            property="EVWCAPI"
            value={`https://api.evware.co.uk/device/${device.id}`}
          />
          <PropertyRow
            property="Fleet ref"
            value="fleet/932a198b-9f62-4fac-94a4-06bd95176504"
          />
          <PropertyRow
            property="Account ref"
            value="account/e454d61c-1118-48a4-b630-d0e3a40be07f"
          />
          <PropertyRow
            property="IoT ARN"
            value={`arn:aws:iot:eu-central-1:877112563653:thing/${device.id}`}
          />
          <PropertyRow
            property="Shadow URL"
            value="https://a1icf4pn56ltmu-ats.iot.eu-central-1.amazonaws.com/things/soc-demo-vehicle-6/shadow"
          />
          <PropertyRow
            property="Shadow MQTT"
            value="$aws/things/soc-demo-vehicle-6/shadow"
          />
          <PropertyRow
            property="Model ARN"
            value="arn:aws:iotfleetwise:eu-central-1:877112563653:model-manifest/soc-demo-model"
          />
          <PropertyRow
            property="Catalog ARN"
            value="arn:aws:iotfleetwise:eu-central-1:877112563653:signal-catalog/DefaultSignalCatalog"
          />
          <PropertyRow property="Campaigns">
            <Copyable>
              arn:aws:iotfleetwise:eu-central-1:877112563653:campaign/soc-campagin-6c
            </Copyable>
            <br />
            <Copyable>
              arn:aws:iotfleetwise:eu-central-1:877112563653:campaign/soc-campagin-6b
            </Copyable>
            <br />
            <Copyable>
              arn:aws:iotfleetwise:eu-central-1:877112563653:campaign/soc-campagin-6a
            </Copyable>
            <br />
            <Copyable>
              arn:aws:iotfleetwise:eu-central-1:877112563653:campaign/soc-campagin-5
            </Copyable>
            <br />
          </PropertyRow>
          <PropertyRow property="Created">{device.createdAt}</PropertyRow>
          <PropertyRow property="Updated">{device.updatedAt}</PropertyRow>
        </tbody>
      </table>
    </div>
  )
}
interface PropertyRowProp {
  property: string
  value?: string | null | undefined
  children?: any
}
function PropertyRow(props: PropertyRowProp) {
  const { property, value, children } = props
  return (
    <tr className="border border-puffin-700/20 hover:bg-stone-400/30">
      <td className="p-1 pr-3 text-right font-bold align-text-top">
        {property}
      </td>
      <td className="p-1 pr-3 text-left align-text-top">
        {children}
        {value && <Copyable description={property}>{value}</Copyable>}
      </td>
    </tr>
  )
}
