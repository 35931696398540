import { useRef } from 'react'
import * as VISS from '@sparrow-emobility/viss-interfaces'
import { v4 as uuidv4 } from 'uuid'

interface DeviceOperationsProps {
  deviceId: string
  handleNewCommand: (command: VISS.WSSRequest) => void
}

export const DeviceOperations = (props: DeviceOperationsProps) => {
  const commandRef = useRef<HTMLSelectElement>(null)
  const subsRef = useRef<HTMLSelectElement>(null)
  const { deviceId } = props

  const handleCommandClick = () => {
    const cmd: VISS.WSSUpdateRequest = {
      action: VISS.WSSAction.SET,
      path: commandRef.current!.selectedOptions[0].dataset.fqn as string,
      value: commandRef.current!.selectedOptions[0].dataset.value as string,
      requestId: uuidv4(),
      authorization: deviceId,
    }
    props.handleNewCommand(cmd)
  }

  const handleSubscribeClick = () => {
    const cmd: VISS.WSSSubscribeRequest = {
      action: VISS.WSSAction.SUBSCRIBE,
      path: subsRef.current!.selectedOptions[0].dataset.fqn as string,
      filter: {
        type: VISS.WSSFilterType.CHANGE,
        value: '',
      },
      requestId: uuidv4(),
      authorization: deviceId,
    }
    props.handleNewCommand(cmd)
  }

  const handleGetValueClick = () => {
    const cmd: VISS.WSSReadRequest = {
      action: VISS.WSSAction.GET,
      path: subsRef.current!.selectedOptions[0].dataset.fqn as string,
      requestId: uuidv4(),
      authorization: deviceId,
    }
    props.handleNewCommand(cmd)
  }

  const handleUnsubscribeClick = () => {
    const cmd: VISS.WSSUnsubscribeRequest = {
      action: VISS.WSSAction.UNSUBSCRIBE,
      subscriptionId: 'THEID',
      requestId: uuidv4(),
      authorization: deviceId,
    }
    props.handleNewCommand(cmd)
  }

  const handleListSubscriptionsClick = () => {
    const cmd: VISS.WSSSubscriptionsRequest = {
      action: VISS.WSSAction.SUBSCRIPTIONS,
      requestId: uuidv4(),
      authorization: deviceId,
    }
    props.handleNewCommand(cmd)
  }

  const option = (fqn: string, value: string) => {
    return (
      <option data-fqn={fqn} data-value={value}>
        {`${fqn} [ ${value} ]`}
      </option>
    )
  }

  return (
    <div className="flex flex-row gap-4 mt-4">
      <div className="basis-1/2 bg-stone-200/20 rounded-md shadow-md mb-4 ring-1 ring-stone-200">
        <h3 className="font-display text-center bg-stone-200 rounded-t-md">
          Device Commands
        </h3>

        <div className="container p-4">
          <select
            className="select select-sm select-bordered w-full max-w-xs mr-2 mb-2"
            ref={commandRef}
          >
            <option disabled>Select a command</option>
            <option disabled>--- HALO Nimbus Light ---</option>
            {option('Vehicle.Cabin.Infotainment.HMI.HALO.Nimbus', 'RED')}
            {option('Vehicle.Cabin.Infotainment.HMI.HALO.Nimbus', 'GREEN')}
            {option(
              'Vehicle.Cabin.Infotainment.HMI.HALO.Nimbus',
              'RGB(12,50,112)',
            )}
            {option('Vehicle.Cabin.Infotainment.HMI.HALO.Nimbus', 'LOADING')}
            {option('Vehicle.Cabin.Infotainment.HMI.HALO.Nimbus', 'OFF')}
            <option disabled>--- HALO Messaging ---</option>
            {option(
              'Vehicle.Cabin.Infotainment.HMI.HALO.Message',
              'Hello, world!',
            )}
            <option disabled>--- HMI Display Theme ---</option>
            {option('Vehicle.Cabin.Infotainment.HMI.DayNightMode', 'NIGHT')}
            {option('Vehicle.Cabin.Infotainment.HMI.DayNightMode', 'DAY')}
            <option disabled>--- Vehicle ---</option>
            {option('Vehicle.Body.Lights.Running.IsActive', 'true')}
            {option('Vehicle.Body.Lights.Running.IsActive', 'false')}
          </select>
          <button
            className="btn btn-outline btn-sm rounded-md"
            onClick={handleCommandClick}
          >
            Send Command
          </button>
        </div>
      </div>

      <div className="basis-1/2 bg-stone-200/20 rounded-lg shadow-md mb-4 ring-1 ring-stone-200">
        <h3 className="font-display text-center bg-stone-200 rounded-t-md">
          Data Values / Subscriptions
        </h3>

        <div className="container p-4">
          <select
            className="select select-sm select-bordered w-full mr-2 mb-2"
            ref={subsRef}
          >
            <option disabled>Select a VSS FQN</option>
            <option data-fqn="Vehicle.Speed">Vehicle.Speed</option>
            <option data-fqn="Vehicle.Powertrain.Battery.StateOfCharge.Current">
              Vehicle.Powertrain.Battery.StateOfCharge.Current
            </option>
            <option data-fqn="Vehicle.Powertrain.Battery.StateOfHealth">
              Vehicle.Powertrain.Battery.StateOfHealth
            </option>
            <option data-fqn="Vehicle.Powertrain.Battery.Range">
              Vehicle.Powertrain.Battery.Range
            </option>
            <option data-fqn="Vehicle.Powertrain.Battery.Charging.IsChargingCableConnected">
              Vehicle.Powertrain.Battery.Charging.IsChargingCableConnected
            </option>
          </select>

          <input
            type="text"
            placeholder="or custom FQN E.g. Vehicle.Drivetrain"
            className="input input-sm input-bordered w-full max-w-xs mb-2 font-mono"
          />
          <br />

          <button
            className="btn btn-outline btn-sm btn-primary mr-2 rounded-md"
            onClick={handleGetValueClick}
          >
            Get Value
          </button>
          <button
            className="btn btn-outline btn-sm btn-success mr-2 rounded-md"
            onClick={handleSubscribeClick}
          >
            Subscribe
          </button>
          <button
            className="btn btn-outline btn-sm btn-error mr-2 rounded-md"
            onClick={handleUnsubscribeClick}
          >
            Unsubscribe
          </button>
          <button
            className="btn btn-outline btn-sm btn-info rounded-md"
            onClick={handleListSubscriptionsClick}
          >
            List Subs
          </button>
        </div>
      </div>
    </div>
  )
}
