import { useState, useEffect } from 'react'
import { Devices } from '../../models'
import DashboardGraph from '../../components/DashboardGraph'
import { Indicator } from '../../components/Indicator'
import { Logo } from '../../components/Logo'
import * as VISS from '@sparrow-emobility/viss-interfaces'
import { DevicePropertyTable } from './DevicePropertyTable'
import { DeviceConsole } from './DeviceConsole'
import {
  DeviceConsoleLogEntry,
  DeviceConsoleLogEntryStatus,
  DeviceConsoleLogEntryType,
} from '../../models/DeviceConsoleLogEntry'
import { w3cwebsocket as W3CWebSocket, IMessageEvent } from 'websocket'
import { DeviceOperations } from './DeviceOperations'
import { differenceInSeconds } from 'date-fns'

interface DeviceInfoProps {
  device: Devices
}

export function DeviceInfo(props: DeviceInfoProps) {
  const { device } = props
  const [isWsOpen, setIsWsOpen] = useState<boolean>(false)
  const [wssclient, setWssclient] = useState<W3CWebSocket>()
  const [logs, setLogs] = useState<DeviceConsoleLogEntry[]>([])

  useEffect(() => {
    if (!device) return

    const c = new W3CWebSocket('wss://ws-api.evware.co.uk', 'VISSv2')
    setWssclient(c)

    c.onopen = () => {
      setIsWsOpen(true)

      const log: DeviceConsoleLogEntry = new DeviceConsoleLogEntry(
        new Date().toISOString(),
        DeviceConsoleLogEntryType.STATUS,
        DeviceConsoleLogEntryStatus.OK,
        `Socket connected`,
      )
      setLogs((prevLogs) => [...prevLogs, log])
    }

    c.onclose = () => {
      setIsWsOpen(false)

      const log: DeviceConsoleLogEntry = new DeviceConsoleLogEntry(
        new Date().toISOString(),
        DeviceConsoleLogEntryType.ERROR,
        DeviceConsoleLogEntryStatus.ERROR,
        `Socket disconnected`,
      )
      setLogs((prevLogs) => [...prevLogs, log])
    }

    c.onmessage = (message: IMessageEvent) => {
      if ((message.data as string)[0] === '{') {
        const o = JSON.parse(message.data as string)
        console.log(o)
        let log: DeviceConsoleLogEntry

        switch (o.action as VISS.WSSAction) {
          case VISS.WSSAction.SUBSCRIPTIONS: {
            const payload: VISS.WSSSubscriptionsResponse = o
            const subs: VISS.SubscriptionInfo[] = payload.data.subscriptions

            const subsText: string[] = subs.map((sub) => {
              const future = new Date(sub.ttl)
              const seconds = differenceInSeconds(future, new Date())
              return `${sub.path} (${
                sub.subscriptionId
              }) expires in: ${seconds.toLocaleString()} seconds`
            })

            log = new DeviceConsoleLogEntry(
              new Date().toISOString(),
              DeviceConsoleLogEntryType.RX,
              DeviceConsoleLogEntryStatus.OK,
              `Active subscriptions:\n${
                subsText.length > 0 ? subsText.join('\n') : 'none'
              }`,
            )
            setLogs((prevLogs) => [...prevLogs, log])
            break
          }

          case VISS.WSSAction.GET: {
            const payload: VISS.WSSReadResponse = o
            const dataPoint = payload.data.dp as VISS.DataPoint
            log = new DeviceConsoleLogEntry(
              new Date().toISOString(),
              DeviceConsoleLogEntryType.VALUE,
              DeviceConsoleLogEntryStatus.DEFAULT,
              `${payload.data.path} [ ${dataPoint.value} ] @ ${dataPoint.ts}`,
            )
            setLogs((prevLogs) => [...prevLogs, log])
            break
          }

          case VISS.WSSAction.SUBSCRIPTION: {
            // console.log('SUBSCRIPTION DATA')
            const payload: VISS.SubscriptionNotification = o
            const dataPoint = payload.data.dp as VISS.DataPoint
            log = new DeviceConsoleLogEntry(
              new Date().toISOString(),
              DeviceConsoleLogEntryType.VALUE,
              DeviceConsoleLogEntryStatus.DEFAULT,
              `${payload.data.path} [ ${dataPoint.value} ] @ ${dataPoint.ts}`,
            )
            setLogs((prevLogs) => [...prevLogs, log])
            break
          }
        }
      }
    }
  }, [device])

  const sendMessage = (command: VISS.WSSRequest) => {
    if (!wssclient) throw new Error('Websocket client is not available')
    // TODO: requestId should be added in here...
    wssclient.send(JSON.stringify(command))
  }

  const handleNewCommand = (command: VISS.WSSRequest) => {
    let log: DeviceConsoleLogEntry

    if (!isWsOpen) {
      log = new DeviceConsoleLogEntry(
        new Date().toISOString(),
        DeviceConsoleLogEntryType.ERROR,
        DeviceConsoleLogEntryStatus.ERROR,
        `Socket connection is closed`,
      )
      setLogs((prevLogs) => [...prevLogs, log])
      return
    }

    switch (command.action) {
      case VISS.WSSAction.GET: {
        const data = command as VISS.WSSUpdateRequest
        sendMessage(command)
        // TODO: understand response and if thisx is ACK/NAK
        log = new DeviceConsoleLogEntry(
          new Date().toISOString(),
          DeviceConsoleLogEntryType.TX,
          DeviceConsoleLogEntryStatus.OK,
          `Tx: ${data.path}`,
        )
        break
      }

      case VISS.WSSAction.SET: {
        const data = command as VISS.WSSUpdateRequest
        sendMessage(command)
        // TODO: understand response and if this is ACK/NAK
        log = new DeviceConsoleLogEntry(
          new Date().toISOString(),
          DeviceConsoleLogEntryType.TX,
          DeviceConsoleLogEntryStatus.OK,
          `Tx: ${data.path} [ ${data.value} ]`,
        )
        break
      }

      case VISS.WSSAction.SUBSCRIBE: {
        const data = command as VISS.WSSSubscribeRequest
        sendMessage(command)
        log = new DeviceConsoleLogEntry(
          new Date().toISOString(),
          DeviceConsoleLogEntryType.SUBSCRIBE,
          DeviceConsoleLogEntryStatus.OK,
          `Subscribed to: ${data.path}`,
        )
        break
      }

      case VISS.WSSAction.UNSUBSCRIBE: {
        const data = command as VISS.WSSUnsubscribeRequest
        sendMessage(command)
        log = new DeviceConsoleLogEntry(
          new Date().toISOString(),
          DeviceConsoleLogEntryType.UNSUBSCRIBE,
          DeviceConsoleLogEntryStatus.WARNING,
          `Unsubscribed from Id: ${data.subscriptionId}`,
        )
        break
      }

      case VISS.WSSAction.SUBSCRIPTIONS: {
        // const data = command as VISS.SubscriptionsRequest
        sendMessage(command)
        log = new DeviceConsoleLogEntry(
          new Date().toISOString(),
          DeviceConsoleLogEntryType.TX,
          DeviceConsoleLogEntryStatus.OK,
          `Requested active subscriptions...`,
        )
        break
      }

      default:
        console.log(`DeviceCommandAction (${command.action}) not recognised`)
        return
    }

    setLogs((prevLogs) => [...prevLogs, log])
  }

  return (
    <div className="text-base max-w-4xl mx-auto bg-gradient-to-r from-hummingbird-900 via-bullfinch-700 to-canary-600 rounded-lg shadow-lg ring-1 ring-stone-600">
      <div className="p-4">
        <div className="flex flex-row items-center">
          <div className="hidden sm:block flex-0 items-start pl-2 pr-6">
            <Logo className="h-10 w-auto fill-white" />
          </div>

          <div className="flex-1">
            <Logo className="sm:hidden w-7 fill-white mb-2" />
            <h2 className="text-white flex-auto font-display text-3xl">
              {device.displayName}
            </h2>
            <p className="text-white/40 font-mono font-semibold text-xs uppercase">
              {device.id}
            </p>
          </div>

          <div className="flex-0 flex flex-col items-end">
            <div className="font-display text-canary-200/80 text-sm font-semibold mb-1">
              {device.modelName}
            </div>
            {/* <Indicator color="#26FF3C" text="Online" /> */}
            <Indicator color="#FF0000" text="Offline" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-b-lg p-2">
        <DashboardGraph />

        <DeviceConsole logs={logs} isWsOpen={isWsOpen} />

        <DeviceOperations
          handleNewCommand={handleNewCommand}
          deviceId={device.id}
        />

        <h3 className="mt-4 font-display text-center">Metadata</h3>
        <DevicePropertyTable device={device} />
      </div>
    </div>
  )
}
