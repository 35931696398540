import { Logo } from '../../components/Logo'

export function ErrorMessage(error: string) {
  console.log(error)
  return (
    <div className="text-base max-w-4xl mx-auto bg-gradient-to-r from-hummingbird-900 via-bullfinch-700 to-canary-600 rounded-lg shadow-lg ring-1 ring-stone-600">
      <div className="p-4">
        <div className="flex flex-row items-center">
          <div className="flex-0 items-start pl-2 pr-6">
            <Logo className="h-10 w-auto fill-white" />
          </div>

          <div className="flex-1">
            <h2 className="text-white flex-auto font-display text-3xl text-center">
              Error
            </h2>
          </div>

          <div className="flex-0 w-20"></div>
        </div>
      </div>

      <div className="bg-white rounded-b-lg p-2 text-center">
        <h3 className="text-puffin-600">{error}</h3>
      </div>
    </div>
  )
}
