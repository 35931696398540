import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Device from './routes/device'
import Home from './routes/home'
import './tailwind.css'
import './index.css'

// AWS Amplify
import { Amplify } from 'aws-amplify'
import awsConfig from './aws-exports'
Amplify.configure(awsConfig)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/:deviceId',
    element: <Device />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  // <WebsocketProvider>
  <div className="container p-2 md:p-7">
    <RouterProvider router={router} />
  </div>,
  // </WebsocketProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
