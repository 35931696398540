function LogomarkPaths(props: any) {
  return (
    <g {...props}>
      <path d="M136.112 196.418L116.842 156.965H43.398V114.976H134.14V76.3695H43.398V39.4528H134.583L115.167 0H0V196.418H136.112Z" />
      <path d="M279.613 0H233.396L178.444 115.258L123.774 0H77.5581L178.444 206L279.613 0Z" />
      <path d="M43.4943 252.23L63.5268 304L87 243.5L81.5691 232.634L63.5268 279.77L43.4943 228L23.4618 279.77L5.41924 232.634L0 243.5L23.4618 304L43.4943 252.23ZM128.225 230.648C118.806 230.648 110.714 234.091 104.213 240.843C97.7123 247.596 94.3956 255.937 94.3956 266C94.3956 276.063 97.7123 284.537 104.213 291.289C110.714 298.042 118.806 301.352 128.225 301.352C140.298 301.352 148.125 296.188 151.973 289.436V299.366H161.79V232.634H151.973V242.564C148.125 235.812 140.298 230.648 128.225 230.648ZM128.225 292.216C121.327 292.216 115.622 289.7 110.979 284.801C106.468 279.77 104.213 273.547 104.213 266C104.213 258.453 106.468 252.23 110.979 247.331C115.622 242.3 121.327 239.784 128.225 239.784C135.389 239.784 141.359 242.3 146.003 247.331C150.779 252.23 153.167 258.453 153.167 266C153.167 273.547 150.779 279.77 146.003 284.801C141.359 289.7 135.389 292.216 128.225 292.216ZM186.55 299.366V260.571C186.55 249.449 194.112 241.77 204.592 241.77C207.245 241.77 209.501 242.035 211.225 242.432V231.84C210.031 231.707 208.572 231.575 207.113 231.575C197.428 231.575 190.132 236.474 186.55 243.889V232.634H176.732V299.366H186.55ZM215.79 266.927C215.79 277.387 218.841 285.861 225.076 292.216C231.312 298.439 239.537 301.617 249.62 301.617C259.437 301.617 267.662 299.101 274.163 294.07V282.948C269.917 288.111 261.692 292.481 249.62 292.481C235.292 292.481 226.138 282.948 225.607 268.118H279.867C280 266.794 280 265.338 280 263.882C280 254.481 277.081 246.669 271.377 240.446C265.672 234.091 258.376 230.913 249.222 230.913C239.537 230.913 231.444 234.223 225.209 240.711C218.974 247.199 215.79 255.672 215.79 266.265V266.927ZM226.27 259.512C226.668 254.084 229.056 249.582 233.434 245.742C237.812 241.902 243.119 240.049 249.222 240.049C254.794 240.049 259.57 242.035 263.549 245.875C267.529 249.714 269.519 254.216 269.519 259.512H226.27Z" />
    </g>
  )
}

export function Logomark(props: any) {
  return (
    <svg aria-hidden="true" viewBox="0 0 36 36" fill="none" {...props}>
      <LogomarkPaths />
    </svg>
  )
}

export function Logo(props: any) {
  return (
    <svg aria-hidden="true" viewBox="0 0 280 304" fill="none" {...props}>
      <LogomarkPaths />
    </svg>
  )
}
