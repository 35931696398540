import { Devices } from '../../models'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api'
import { getDevices } from '../../graphql/queries'
import { ErrorMessage } from './ErorMessage'
import { DeviceInfo } from './DeviceInfo'

export default function Device(props: any) {
  const [device, setDevice] = useState<Devices>()
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [error, setError] = useState<string>()
  // const serial = 'fb94375b-01a7-4bea-9a07-69ae1997192c'
  const { deviceId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      if (!deviceId) {
        setError('No serial number provided')
        return
      }

      const res: GraphQLResult<any> = await API.graphql(
        graphqlOperation(getDevices, { id: deviceId }),
      )

      if (!res.data.getDevices) {
        setError(`Could not find a device with serial number ${deviceId}`)
        return
      }

      setDevice(res.data.getDevices)
    }

    fetchData()
      .catch((err: Error) => setError(err.message))
      .finally(() => {
        setIsLoading(false)
      })
  }, [deviceId])

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return ErrorMessage(error)
  }

  return <DeviceInfo device={device!} />
}
