import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
)

export default function DashboardGraph(props: any) {
  const chartOptions = {
    maintainAspectRatio: false,
    tension: 0.4,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  }

  const chart1data = {
    labels: [1, 2, 1, 3, 5, 4, 7],
    datasets: [
      {
        fill: 'origin',
        backgroundColor: 'rgba(101, 116, 205, 0.1)',
        borderColor: 'rgba(101, 116, 205, 0.8)',
        borderWidth: 2,
        data: [1, 2, 1, 3, 5, 4, 7],
      },
    ],
  }

  const chart2data = {
    labels: [2, 3, 2, 9, 7, 7, 4],
    datasets: [
      {
        fill: 'origin',
        backgroundColor: 'rgba(246, 109, 155, 0.1)',
        borderColor: 'rgba(246, 109, 155, 0.8)',
        borderWidth: 2,
        data: [2, 3, 2, 9, 7, 7, 4],
      },
    ],
  }

  const chart3data = {
    labels: [2, 5, 1, 3, 2, 6, 7],
    datasets: [
      {
        fill: 'origin',
        backgroundColor: 'rgba(246, 153, 63, 0.1)',
        borderColor: 'rgba(246, 153, 63, 0.8)',
        borderWidth: 2,
        data: [2, 5, 1, 3, 2, 6, 7],
      },
    ],
  }

  return (
    <>
      <div className="flex items-center justify-center px-5 pt-5 pb-5">
        <div className="w-full max-w-3xl ">
          <div className="-mx-2 md:flex">
            <div className="w-full md:w-1/3 px-2">
              <div className="rounded-lg shadow-sm mb-4 ring-1 ring-stone-200">
                <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                  <div className="px-3 pt-8 pb-10 text-center relative z-10">
                    <h4 className="text-sm uppercase text-gray-500 leading-tight">
                      Alarms (Last 24h)
                    </h4>
                    <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                      3
                    </h3>
                    <p className="text-xs text-red-500 leading-tight">▲ 100%</p>
                  </div>
                  <div className="absolute bottom-0 inset-x-0">
                    <Line
                      data={chart1data}
                      options={chartOptions}
                      className="h-20"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-2">
              <div className="rounded-lg shadow-sm mb-4 ring-1 ring-stone-200">
                <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                  <div className="px-3 pt-8 pb-10 text-center relative z-10">
                    <h4 className="text-sm uppercase text-gray-500 leading-tight">
                      Events (Last 24h)
                    </h4>
                    <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                      11,427
                    </h3>
                    <p className="text-xs text-green-500 leading-tight">
                      ▼ 42.8%
                    </p>
                  </div>
                  <div className="absolute bottom-0 inset-x-0">
                    <Line
                      data={chart2data}
                      options={chartOptions}
                      className="h-20"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-2">
              <div className="rounded-lg shadow-sm mb-4 ring-1 ring-stone-200">
                <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                  <div className="px-3 pt-8 pb-10 text-center relative z-10">
                    <h4 className="text-sm uppercase text-gray-500 leading-tight">
                      Active campaigns
                    </h4>
                    <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                      12
                    </h3>
                    <p className="text-xs text-green-500 leading-tight">
                      &nbsp;
                    </p>
                  </div>
                  <div className="absolute bottom-0 inset-x-0">
                    <Line
                      data={chart3data}
                      options={chartOptions}
                      className="h-20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
