import { Logo } from '../../components/Logo'

export default function Home(props: any) {
  return (
    <div className="text-base max-w-4xl mx-auto bg-gradient-to-r from-hummingbird-900 via-bullfinch-700 to-canary-600 rounded-lg shadow-lg ring-1 ring-stone-600">
      <div className="p-4">
        <div className="flex flex-row items-center">
          <div className="hidden sm:block flex-0 items-start pl-2 pr-6">
            <Logo className="h-10 w-auto fill-white" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-b-lg p-2">
        <h1 className="font-display text-xl">Device information service</h1>
      </div>
    </div>
  )
}
