import { useRef, useEffect } from 'react'
import { DeviceConsoleLogEntry } from '../../models/DeviceConsoleLogEntry'
import parse from 'html-react-parser'

interface DeviceConsoleProps {
  logs: DeviceConsoleLogEntry[]
  isWsOpen: boolean
}

export const DeviceConsole = (props: DeviceConsoleProps) => {
  const { logs, isWsOpen } = props
  const consoleRef = useRef<HTMLPreElement>(null)

  const updateScroll = () => {
    consoleRef.current!.scrollTop = consoleRef.current!.scrollHeight
  }

  useEffect(() => {
    updateScroll()
  }, [])

  return (
    <>
      <pre className="rounded-t-md text-white bg-puffin-900 font-bold text-sm p-2 pt-1 pb-">
        Device Console
      </pre>
      <pre
        ref={consoleRef}
        className="bg-puffin-800 p-2 text-xs text-puffin-200 h-64 w-full overflow-y-scroll break-words whitespace-normal"
      >
        {logs.map((log, i) => {
          return <RenderLogEntry log={log} key={`log-${i}`} />
        })}
      </pre>
      <pre
        className={`${
          isWsOpen ? 'bg-green-900' : 'bg-red-900'
        } p-2 text-xs text-white rounded-b-md`}
      >
        API Socket status:&nbsp;
        {isWsOpen ? (
          <>
            <span className="text-lime-500">●</span> Connected
          </>
        ) : (
          <>
            <span className="text-red-500">●</span> Disconnected
          </>
        )}
      </pre>
    </>
  )
}

const RenderDateTime = ({ ts }: { ts: string }) => {
  return (
    <span className="text-puffin-700">
      <span className="hidden md:inline-block">{ts.split('T')[0]} </span>
      <span>{ts.split('T')[1]}</span>
    </span>
  )
}

const RenderLogEntry = ({ log }: { log: DeviceConsoleLogEntry }) => {
  const newMessage = log.message
    .replace(/(\[ .* \])/g, `<span className="text-pink-500">$&</span>`)
    .replace(/(@.*$)/g, `<span className="text-gray-500">$&</span>`)

  return (
    <>
      <RenderDateTime ts={log.ts} />{' '}
      <span className={log.iconColorClass}>{log.icon}</span> {parse(newMessage)}
      <br />
    </>
  )
}
