export enum DeviceConsoleLogEntryType {
  TX,
  RX,
  SUBSCRIBE,
  UNSUBSCRIBE,
  VALUE,
  ERROR,
  STATUS,
}
export enum DeviceConsoleLogEntryStatus {
  DEFAULT,
  OK,
  ERROR,
  WARNING,
}
export class DeviceConsoleLogEntry {
  ts: string
  type: DeviceConsoleLogEntryType
  status: DeviceConsoleLogEntryStatus
  message: string

  constructor(
    ts: string,
    type: DeviceConsoleLogEntryType,
    status?: DeviceConsoleLogEntryStatus,
    message?: string,
  ) {
    this.ts = ts
    this.type = type
    this.status = status || DeviceConsoleLogEntryStatus.DEFAULT
    this.message = message || ''
  }

  get icon(): string {
    switch (this.type) {
      case DeviceConsoleLogEntryType.TX:
        return '↑'
      case DeviceConsoleLogEntryType.RX:
        return '↓'
      case DeviceConsoleLogEntryType.SUBSCRIBE:
        return '⌾'
      case DeviceConsoleLogEntryType.UNSUBSCRIBE:
        return '⌾'
      case DeviceConsoleLogEntryType.VALUE:
        return '→'
      case DeviceConsoleLogEntryType.ERROR:
        return '𐄂'
      case DeviceConsoleLogEntryType.STATUS:
        return '✓'
      default:
        return '&nbsp;'
    }
  }

  get iconColorClass(): string {
    switch (this.status) {
      case DeviceConsoleLogEntryStatus.OK:
        return 'text-lime-500'
      case DeviceConsoleLogEntryStatus.ERROR:
        return 'text-red-500'
      case DeviceConsoleLogEntryStatus.WARNING:
        return 'text-orange-500'
      default:
        return ''
    }
  }
}
